import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Container } from '../components/container'
import { SectionTitle } from '../components/section-title'
import { GatsbyImage } from 'gatsby-plugin-image'

const BlogPostPage = ({ data }) => {
  const updatedLinks = data.links.nodes.reduce(
    (newObj, item) => ((newObj[item.locale] = item.slug), newObj),
    {}
  )

  const blogPost = data.blogPost

  return (
    <Layout hrefLangLinks={updatedLinks} seoMetaTags={blogPost.seoMetaTags}>
      <Container color="bg-beige">
        {/* SHOWCASE IMG SMALL SCREENS */}
        <div className="w-100 p-0 sm:hidden">
          <GatsbyImage
            image={{ ...blogPost.imageSummary.gatsbyImageData }}
            alt="blog post image"
          />
        </div>
        <div className="max-w-4xl mx-auto py-4 space-y-4 sm:space-y-10 sm:pt-0">
          <p className="text-abel-green font-lg uppercase">
            {blogPost.meta.publishedAt}
          </p>
          <SectionTitle title={data.blogPost.title} main={true} />
        </div>
        {/* SHOWCASE IMG LARGE SCREENS */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-4 hidden sm:block">
          <GatsbyImage
            image={{ ...blogPost.imageSummary.gatsbyImageData }}
            alt="w-full"
          />
        </div>

        <div
          dangerouslySetInnerHTML={{ __html: blogPost.content }}
          className="prose lg:prose-xl max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-6"
        ></div>
      </Container>
    </Layout>
  )
}

export default BlogPostPage

export const blogPostQuery = graphql`
  query ($id: String!, $originalId: String!) {
    blogPost: datoCmsBlogPost(id: { eq: $id }) {
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content
      imageSummary {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
      author {
        avatar {
          url
        }
        name
        headline
        twitterHandle
      }
      meta {
        publishedAt(formatString: "MMMM DD, YYYY")
      }
    }

    links: allDatoCmsBlogPost(filter: { originalId: { eq: $originalId } }) {
      nodes {
        slug
        locale
      }
    }
  }
`
